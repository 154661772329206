<template>
  <div id="topNavDiv">
    <top-nav :pageMode="pageMode"></top-nav>
  </div>

  <div class="mainContainer container" style="">


    <div>
      <div id="family" v-if="pageMode === 'last30days' || pageMode === 'upcoming'">
        <div class="info" v-if="pageMode === 'last30days'">
          <div><img class="logo" src="/images/logo_golden_wo_text.svg" alt=""></div>
          <div>Recent transactions from kids accounts</div>
        </div>

        <div class="info" v-if="pageMode === 'upcoming'">
          <div><img class="logo" src="/images/logo_golden_wo_text.svg" alt=""></div>
          <div>Scheduled transactions from kids accounts</div>
        </div>

        <hr>


        <div id="transDiv">
          <div class="tran" v-for="(tran, idx) in trans" :key="tran.ID" @click="openTranDetails(idx)">
            <div class="tranDate">
              {{tran.TRAN_DATE}}
              <div class="sharedIcon" v-if="tran.SHARED === '1'"><font-awesome-icon :icon="['fas', 'share']" class=""/></div>
            </div>
            <div class="tranBottom">

              <div class="avatar"><img :src="getUserAvatar(tran.USER_ID)" alt="avatar"></div>
              <div class="desc">{{truncateString(tran.DETAILS,this.shortLength)}}</div>
              <div class="amount" :class="numberColor(tran.AMOUNT)">
                <div>{{this.vueNumberFormat(tran.AMOUNT)}}</div>
                <div class="currencyCode">{{tran.CURRENCY_CODE}}</div>
              </div>
            </div>
          </div>

          <div class="clickable loadMore" @click="loadMore()" v-if="!allLoaded && trans.length > 0">Load more</div>
          <div class="clickable loadMore" v-else>{{noTranToDisplayTxt}}</div>
          <div class="alert gotoBtnDiv clickable" @click="goToUserPage(trans[0].USER_ID)" >View all transaction history at each member's banking center</div>
        </div>
      </div>
    </div>


  </div>


  <div class="modalParent tranDetailsModal">
    <vue-final-modal v-model="showTranDetailsModal" classes="modal-container" content-class="modal-content">
      <div class="close modal__close" @click="closeTranDetailsModal">X</div>
      <div class="modal-body">
        <div>
          <tran-details :tran="activeTran" :isAdmin="isAdmin" :pageMode="pageMode" @showDelModal="showDeleteModalFunc" @editTran="gotoEditTran"></tran-details>
        </div>
        <div style="display:flex;justify-content: center;">
          <div class="btn closeModal" @click="closeTranDetailsModal"><font-awesome-icon :icon="['fas', 'times']"/> Close</div>
        </div>

      </div>
    </vue-final-modal>
  </div>


  <div class="modalParent">
    <vue-final-modal v-model="showDeleteModal" classes="modal-container" content-class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="">Are you sure you want to delete this transaction?</h5>
      </div>

      <div class="modal-body">
        <div style="display:flex;justify-content: center;">
          <div class="btn delConfirmBtn delYes" @click="delTran"><font-awesome-icon :icon="['fas', 'trash']"/> Yes</div>
          <div class="btn delConfirmBtn delNo" @click="showDeleteModal=false"><font-awesome-icon :icon="['fas', 'times']"/> No</div>
        </div>
      </div>
    </vue-final-modal>
  </div>



</template>

<script>

import {VueFinalModal } from 'vue-final-modal';
import TranDetails from '@/components/TranDetails';
import 'vue3-carousel/dist/carousel.css';
import TopNav from "@/components/BankingCenter/TopNav";
import {RRule} from "rrule";

var d = new Date();

export default {
  name: 'KidTrans',
  components: {
    VueFinalModal,
    TranDetails,
    TopNav,
    //Carousel,
    //Slide,
  },
  props: {
    pageMode:String
  },
  data(){
    return{
      isAdmin:false,
      trans:[],
      familyUsers:[],
      activeTranIdx:null,
      showTranDetailsModal:false,
      showDeleteModal:false,
      shortLength:120,
      allLoaded:false,
      batchSize:10,
      batch:0,
      days:365,
      schedules:[],
      upcomingInitLoaded:false,
      upcomingTrans:[],
    }
  },

  computed:{
    activeTran(){
      return  this.activeTranIdx !== null ?
          this.trans[this.activeTranIdx]
          : {}
    },
    isSchedule(){
      return this.pageMode === 'upcoming' ? '1' : '0';
    },

    noTranToDisplayTxt(){
      return this.trans.length > 0 ? 'No more transaction to display' : 'No transaction to display';
    },

  },


  methods:{

    getKidsTrans: async function(){
      let loader = this.$loading.show();

      if(this.pageMode === 'last30days') {
        this.axios
            .post(this.$hostApi, {
              task: 'getKidsTrans',
              type: this.pageMode,
              today: d.toLocaleDateString('en-CA'),
              batchSize: this.batchSize,
              batch: this.batch,
              days: this.days
            }, {
              headers: {
                'token': localStorage.getItem('user')
              }
            })
            .then(response => {

              this.batch++;

              this.trans.push(...response.data);
              if (response.data.length === 0) {
                this.allLoaded = true;


              }


            })
            .catch(error => {
              this.errorMessage = error.message;
              console.error("There was an error!", error);
            })
      }else if (this.pageMode === 'upcoming') {
        this.batch++;

        await this.$root.doRefresh();

        this.schedules = JSON.parse(localStorage.getItem('schedules'));
        this.upcomingTrans = this.$root.getAllUpcomingTrans(this.schedules, true, true);
        this.trans = this.upcomingTrans.slice(0, this.batchSize * this.batch);
        this.upcomingInitLoaded = true;

      }

      //console.log(this.trans);
      loader.hide();
    },


    getUser(userId){
      return this.familyUsers.filter(user => user.ID === userId)[0];
    },

    getUserAvatar(userId){
      var user = this.familyUsers.filter(user => user.ID === userId)[0];
      //console.log(user.AVATAR_URL);
      return user.AVATAR_URL;
    },

    numberColor:function(num){
      return num < 0 ? 'negative' : 'positive';
    },

    inout:function(num){
      return num < 0 ? 'spent' : 'received';
    },

    openTranDetails(idx){
      this.activeTranIdx = idx;
      //console.log(this.activeTran);
      this.showTranDetailsModal=true;
    },
    closeTranDetailsModal(){
      this.showTranDetailsModal=false
      //this.activeTranIdx = null;
    },
    goToUserPage(userId){
      this.$root.gotoRoute({ path: '/bc/member', query: { userId: userId } })
    },



    genDateHtml(d){
      var parts = d.split('-');
      const date = new Date();
      date.setMonth(parseInt(parts[1]))
      date.toLocaleString('en-US', { month: 'narrow' })
      return "<div><div class='cDay'>"+parts[2]+"</div><div class='cMonth'>"+date.toLocaleString('en-US', { month: 'short' })+"</div><div class='cYear'>"+parts[0]+"</div></div>";
    },


    showDeleteModalFunc(tranId){
      this.$root.playClickSound();
      this.showDeleteModal = true;
      //this.activeTranIdx = tranId;
    },

    truncateString(str, n) {
      if (str.length > n) {
        return str.substring(0, n) + "...";
      } else {
        return str;
      }
    },

    delTran: async function(){
      this.$root.playClickSound();
      await this.axios
          .post(this.$hostApi,{task:'delTran', tranBaId:this.activeTran.ACCOUNT_ID, tranId:this.activeTran.ID},{
            headers:{
              'token':localStorage.getItem('user')
            }
          });
      this.showDeleteModal = false;
      this.showTranDetailsModal = false;
      this.chartDataLoaded = false;
      await this.$root.doRefresh();
      this.trans = this.trans.filter(t => t.ID !== this.activeTran.ID);
      this.activeTranIdx = null;
    },

    gotoEditTran:function(tranId){
      this.$root.gotoRoute({ path: '/tran/edit', query: { userId: this.activeTran.USER_ID, tranId:tranId,  isSchedule:this.isSchedule} })
    },

    convertTran:function(tranId){
      this.$root.gotoRoute({ path: '/tran/convert', query: { userId: this.activeTran.USER_ID, tranId:tranId } })
    },

    loadMore:function(){
      if(this.pageMode === 'upcoming' && this.upcomingInitLoaded){
          this.batch++;
          this.trans = this.upcomingTrans.slice(0, this.batchSize*this.batch);
      }else{
        this.getKidsTrans();
      }
    },


  },
  created() {
    this.familyUsers = JSON.parse(localStorage.getItem('familyUsers'));
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    this.isAdmin = userInfo && userInfo.FAMILY_ADMIN === '1';
  },
  mounted() {
    this.getKidsTrans();
  },

}
</script>

<style scoped>


  .mainContainer{
    margin-top:150px;
  }

  .logo{
    width:40px;
    height:40px;
    padding:3px;
    border-radius: 50%;
    border:1px solid #0dcaf0;
    filter: invert(68%) sepia(64%) saturate(2311%) hue-rotate(145deg) brightness(97%) contrast(95%);
    margin-bottom:10px;
  }

  .info{
    font-size:20px;
    text-align: center;

  }


  #transDiv{
    margin-top:30px;
  }

  .tran{
    height:110px;
    box-shadow: 1px 0 2px 0 rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 19%);
    margin:15px 0;
    border-radius: 3px;
    background-color: #f6f6c6;
  }

  .tranDate{
    text-align: center;
    font-size:12px;
    background-color: #a4a415;
    color:#ffffff;
    padding:5px;
  }

  .sharedIcon{
    float:right;
    margin-right:10px;
  }

  .tranBottom{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding:10px;
  }





  .leftDiv{
    display: flex;
    justify-content: left;
    align-items: center;
  }

  .avatar{
    margin-left:5px;
    width:65px;
  }

  .avatar img{
    width:60px;
    height:60px;
    border:2px solid #9c9c9c;
    border-radius: 50%;
    padding:3px;
  }

  .desc{
    margin-left:20px;
    font-size:11px;
    width:calc(100% - 165px);
  }



  .amount{
    font-size:18px;
    width:100px;
    text-align: right;
  }

  .currencyCode{
    font-size:9px;
    text-align: right;
  }






  .gotoBtnDiv{
    background-color: #b7fa8b;
  }





  .lightBulb{
    color:orange;
    margin-right:10px;
  }

  .shareDiv{
    margin-top:20px;
    background-color: #b7fa8b;
    display: flex;
    justify-content: left;
    align-items: center;
    padding:10px;
    border-radius: 3px;

  }

  .shareIconDiv{
    padding:0 20px 0 10px;
    font-size:20px;
    color:orange;
  }

  .delYes {
    background-color: red;
    color: #fff;
  }

  .delConfirmBtn {
    text-align: center;
    border: 1px solid #f5f5f5;
    margin: 10px;
  }

  .delNo {
    background-color: #e4e4e4;
    color: #3b3b3b;
  }

  .loadMore{
    text-align: center;
    width:200px;
    margin:30px auto;
    font-size:13px;
  }




  /*modal*/
  .modalParent :deep(.modal-container) {
    width:100%;
    top:20px;

  }
  .modalParent :deep(.modal-content){
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 0 1rem !important;
    padding: 1rem !important;
    border: 1px solid #e2e8f0;
    border-radius: 0.25rem;
    background: #f6f6c6;
    width:unset;

  }
  .modal__title {
    margin: 0 2rem 0 0;
    font-size: 14px;
    font-weight: 700;
  }
  .modal__close {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    border: 0 solid #e2e8f0;
  }

  .tranDetailsModal .modal-header{
    display: none;
  }


  .btn.closeModal {
    background-color: #e3e1e1;
    border: 0;
    padding:5px 10px;
    margin-top:20px;
  }

/*end of modal */


</style>
