<template>
  <div id="topNavDiv" class="">
    <div class="topNavTab clickable " :class="{active: pageMode === 'accounts'}" @click="gotoPage('')">Accounts & Balances</div>
    <div class="topNavTab clickable " :class="{active: pageMode === 'last30days'}" @click="gotoPage('recent')">Recent</div>
    <div class="topNavTab clickable" :class="{active: pageMode === 'upcoming'}" @click="gotoPage('upcoming')">Upcoming</div>
  </div>
</template>

<script>
export default {
  name: 'TopNav',
  props: {
    pageMode:String
  },
  data(){
    return{
    }
  },
  methods:{

    gotoPage(mode){
      this.$root.playClickSound();
      this.$root.gotoRoute({ path: '/bc/' + mode });
    }
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#topNavDiv{
  width:100%;
  height:130px;
  background-color: #ffffff;
  display: flex;
  justify-content:center;
  align-items: flex-end;
  position:fixed;
  top:0;
  z-index: 9;
  border-bottom: 2px solid #7ccb1b;
}

.topNavTab{
  border-radius:2px 2px 0 0 ;
  padding:10px 20px;
  margin:0 2px;
  white-space: nowrap;
  background-color: #ffffff;
  text-align: center;
  font-size:13px;
}

.topNavTab.active{
  color: #f9f9f9;
  background-color: #7ccb1b;
}
</style>
